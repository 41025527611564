@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 5%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer components {
  .text-12 {
    @apply text-[12px];
  }

  .text-13 {
    @apply text-[13px];
  }

  .text-14 {
    @apply text-[14px];
  }

  .text-16 {
    @apply text-[12px] md:text-[16px];
  }

  .text-18 {
    @apply text-[14px] xl:text-[16px] 2xl:text-[18px];
  }

  .text-19 {
    @apply text-[19px];
  }

  .text-20 {
    @apply text-[14px] xl:text-[18px] 2xl:text-[20px];
  }

  .text-25 {
    @apply text-[16px] xl:text-[20px] 2xl:text-[25px];
  }

  .text-26 {
    @apply text-[16px] xl:text-[20px] 2xl:text-[26px];
  }

  .text-28 {
    @apply text-[28px];
  }

  .text-30 {
    @apply text-[16px] xl:text-[25px] 2xl:text-[30px];
  }

  .text-36 {
    @apply text-[20px] xl:text-[25px] 2xl:text-[36px];
  }

  .text-40 {
    @apply text-[28px] xl:text-[32px] 2xl:text-[40px];
  }

  .text-45 {
    @apply text-[30px] xl:text-[35px] 2xl:text-[45px];
  }

  .text-70 {
    @apply text-[20px] md:text-[30px] xl:text-[50px] 2xl:text-[70px];
  }

  .primaryButton {
    @apply text-13 font-[500] text-[#ffffff] bg-primaryColor rounded-[7px] py-2 px-5;
  }

  .secondaryButton {
    @apply text-13 font-[500] text-white py-2 px-5 border border-white rounded-[7px] bg-gradient-to-b from-[#32353827] to-[#32353866];
  }

  .titleHeading {
    @apply text-nowrap text-36 text-white font-[500] relative mx-auto text-center after:absolute after:content-[url('/images/line.svg')] after:w-[300px] after:overflow-hidden after:left-1/2 after:translate-x-[-35%] after:block after:-top-[3rem] 2xl:after:-top-10 mb-10;
    /* after:left-[4.5rem] md:after:left-[16rem] lg:after:left-[22rem] xl:after:left-[28rem] 2xl:after:left-[33rem] */
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-[#35251E];
    direction: rtl;
  }
}
body {
  pointer-events: auto !important;
}

* {
  scroll-behavior: smooth;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.rmsc {
  border-radius: 10px !important;
}

.rmsc .dropdown-container {
  background-color: #695750 !important;
  height: 60px;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
}

.rmsc .dropdown-container .dropdown-content {
  background-color: #695750 !important;
  border-radius: 10px !important;
  border: none !important;
}

.multi-select-container {
  background-color: #695750 !important;
}

.rmsc .dropdown-content .panel-content {
  background-color: #695750 !important;
}

.rmsc .item-renderer {
  gap: 5px;
}

.rmsc .select-item:hover,
.rmsc .select-item.selected,
.rmsc .search input:focus {
  background-color: transparent !important;
}

.rmsc .item-renderer input {
  accent-color: #f95821 !important;
}

/* *{
  border: 1px solid red;
} */

/* customization quill for description */
.quill .ql-toolbar.ql-snow,
.quill .ql-container.ql-snow {
  border: none;
}

.object-cover {
  height: auto !important;
}

.quill .ql-toolbar.ql-snow {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  z-index: 10;
}

.quill .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.quill .ql-toolbar.ql-snow .ql-formats button {
  background: #fff;
  border-radius: 5px;
  height: 30px;
  padding: 6px;
  width: 30px;
}

.quill .ql-editor {
  text-align: right;
}

.quill .ql-editor.ql-blank::before {
  color: #fff;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

#wifi-loader {
  --background: #62abff;
  --front-color: #f95821;
  --back-color: #2a2c2f;
  --text-color: #414856;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg circle {
  position: absolute;
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-100deg);
  transform-origin: center;
}

#wifi-loader svg circle.back {
  stroke: var(--back-color);
}

#wifi-loader svg circle.front {
  stroke: var(--front-color);
}

#wifi-loader svg.circle-outer {
  height: 86px;
  width: 86px;
}

#wifi-loader svg.circle-outer circle {
  stroke-dasharray: 62.75 188.25;
}

#wifi-loader svg.circle-outer circle.back {
  animation: circle-outer135 1.8s ease infinite 0.3s;
}

#wifi-loader svg.circle-outer circle.front {
  animation: circle-outer135 1.8s ease infinite 0.15s;
}

#wifi-loader svg.circle-middle {
  height: 60px;
  width: 60px;
}

#wifi-loader svg.circle-middle circle {
  stroke-dasharray: 42.5 127.5;
}

#wifi-loader svg.circle-middle circle.back {
  animation: circle-middle6123 1.8s ease infinite 0.25s;
}

#wifi-loader svg.circle-middle circle.front {
  animation: circle-middle6123 1.8s ease infinite 0.1s;
}

#wifi-loader svg.circle-inner {
  height: 34px;
  width: 34px;
}

#wifi-loader svg.circle-inner circle {
  stroke-dasharray: 22 66;
}

#wifi-loader svg.circle-inner circle.back {
  animation: circle-inner162 1.8s ease infinite 0.2s;
}

#wifi-loader svg.circle-inner circle.front {
  animation: circle-inner162 1.8s ease infinite 0.05s;
}

#wifi-loader .text {
  position: absolute;
  bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: lowercase text-center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}

#wifi-loader .text::before,
#wifi-loader .text::after {
  content: attr(data-text);
}

#wifi-loader .text::before {
  color: var(--text-color);
}

#wifi-loader .text::after {
  color: var(--front-color);
  animation: text-animation76 3.6s ease infinite;
  position: absolute;
  left: 0;
}

@keyframes circle-outer135 {
  0% {
    stroke-dashoffset: 25;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 301;
  }

  80% {
    stroke-dashoffset: 276;
  }

  100% {
    stroke-dashoffset: 276;
  }
}

@keyframes circle-middle6123 {
  0% {
    stroke-dashoffset: 17;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 204;
  }

  80% {
    stroke-dashoffset: 187;
  }

  100% {
    stroke-dashoffset: 187;
  }
}

@keyframes circle-inner162 {
  0% {
    stroke-dashoffset: 9;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 106;
  }

  80% {
    stroke-dashoffset: 97;
  }

  100% {
    stroke-dashoffset: 97;
  }
}

@keyframes text-animation76 {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  50% {
    clip-path: inset(0);
  }

  100% {
    clip-path: inset(0 0 0 100%);
  }
}

.AccordionItem[data-state="open"] {
  background: var(--Card-color-light1, #695750);
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: 0 0 6px rgba(46, 46, 46, 0.3) inset;
}

::-webkit-scrollbar-thumb {
  background: #f95821;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(46, 46, 46, 0.3) inset;
}

option {
  width: fit-content !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* Custom scrollbar styles */
.custom-scrollbar-dark::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  height: 8px;
}

.custom-scrollbar-dark::-webkit-scrollbar-track {
  background: #323538;
  /* Color of the scrollbar track */
}

.custom-scrollbar-dark::-webkit-scrollbar-thumb {
  background: #3c3f42;
  /* Color of the scrollbar thumb */
}

.custom-scrollbar-dark::-webkit-scrollbar-thumb:hover {
  background: #323538;
  /* Color of the scrollbar thumb when hovered */
}

/* Style for Shadcn Dialog overlay */
[data-state="open"] {
  z-index: 999 !important; /* Adjust this to lower if necessary */
}

/* Style to ensure SweetAlert stays on top */
.custom-toast {
  z-index: 110000 !important; /* Keep this higher than the dialog */
}
